<template>
  <footer class="mt-5 py-4 bg-light">
    <div class="container">
      <div class="contact">
        <p class="mb-0">E-mail <a :href="emailLink">uas.ts@crs.kz</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      emailLink: 'mailto:uas.ts@crs.kz'
    };
  }
};
</script>